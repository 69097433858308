import React, {useContext} from 'react';
import {motion} from 'framer-motion';
import {IoLanguage} from 'react-icons/io5';
import {HiMenu} from 'react-icons/hi';

import DotMenu from '../DotMenu';

import logo from '../../assets/shoppey-logo.svg'
import facebook from '../../assets/icons/facebook.png'
import insta from '../../assets/icons/insta.png'
import linkedin from '../../assets/icons/linkedin.png'
import tiktok from '../../assets/icons/tiktok.png'
import en from '../../assets/icons/en.png'
import fr from '../../assets/icons/fr.png'
import alg from '../../assets/icons/alg.png'

import {LangContext} from '../../Context'

const NavBar = () => {
    const {language, handleLanguage} = useContext(LangContext);

    return (
        <header id='Home' className='font-uniSansThin flex items-center justify-between
            pt-2
            sm:pt-4
            md:pt-4
            lg:pt-4
            xl:pt-4
            2xl:pt-4'
        >
            <motion.div
                animate={{
                    scale: [1, 0.5, 1.3, 1],
                    rotate: [0, 360, 360, 360, 360, 0],
                }}
                transition={{
                    duration: 3,
                    delay: 2,
                }}
            >
                <a href='/'>
                    <img src={logo} className='hover:animate-[ping_4s_ease_infinite] delay-300
                        ml-[5vw] w-[22vw]
                        sm:w-[14vw] sm:ml-0
                        md:w-[14vw]
                        lg:w-[14vw]
                        xl:w-[14vw]
                        2xl:w-[14vw]'
                    />
                </a>
            </motion.div>

            <div className='flex items-center justify-between
                w-[55%] invisible
                sm:w-[55%] sm:visible
                2xl:w-3/5 2xl:gap-12'
            >
                <nav className='flex text-white font-light justify-between
                    sm:text-[1.6vw] sm:w-[50%]
                    md:text-[1.4vw] md:w-[47%]
                    lg:text-[1.3vw] lg:w-[47%]
                    xl:text-[1.3vw] xl:w-[47%]
                    2xl:text-[1.5vw] 2xl:w-[50%]'
                >
                    <div>
                        <a className='hover:font-normal hover:underline decoration-shopOrange underline-offset-8 cursor-pointer'
                           onClick={() => handleScroll('Home')}
                        >{language.home}</a>
                    </div>

                    <div>
                        <a className='hover:font-normal hover:underline decoration-shopOrange underline-offset-8 cursor-pointer'
                           onClick={() => handleScroll('About')}
                        >{language.about}</a>
                    </div>

                    <div>
                        <a className='hover:font-normal hover:underline decoration-shopOrange underline-offset-8 cursor-pointer'
                           onClick={() => handleScroll('Manual')}
                        >{language.manual}</a>
                    </div>

                    <div>
                        <a className='hover:font-normal hover:underline decoration-shopOrange underline-offset-8 cursor-pointer'
                           onClick={() => handleScroll('Contact')}
                        >{language.contact}</a>
                    </div>
                </nav>

                {/** Start Social Media */}
                <div className=' flex w-[32%] items-center justify-between
                    sm:w-[26%]'
                >
                    <div>
                        <a href='https://www.tiktok.com/@shoppey_b2b' target="_blank">
                            <img src={tiktok} className='
                                sm:w-[2.5vw]
                                md:w-[2.5vw]
                                lg:w-[2.5vw]
                                xl:w-[2.5vw]
                                2xl:w-[2.6vw]'
                            />
                        </a>
                    </div>
                    <div>
                        <a href='#'>
                            <img src={insta} className='
                                sm:w-[3.2vw]
                                md:w-[3.2vw]
                                lg:w-[3.2vw]
                                xl:w-[3.2vw]
                                2xl:w-[3.2vw]'
                            />
                        </a>
                    </div>
                    <div>
                        <a href='https://web.facebook.com/profile.php?id=100093951446474' target="_blank">
                            <img src={facebook} className='
                                sm:w-[2.4vw]
                                md:w-[2.4vw]
                                lg:w-[2.4vw]
                                xl:w-[2.4vw]
                                2xl:w-[2.4vw]'
                            />
                        </a>
                    </div>
                    <div>
                        <a href='https://www.linkedin.com/company/shoppey-plus/' target="_blank">
                            <img src={linkedin} className='
                                sm:w-[2.4vw]
                                md:w-[2.4vw]
                                lg:w-[2.4vw]
                                xl:w-[2.4vw]
                                2xl:w-[2.5vw]'
                            />
                        </a>
                    </div>
                </div>
                {/** End Social Media */}

                {/** Start Languages */}
                <div>
                    <div className='
                        peer text-white
                        sm:w-[3vw]
                        md:h-[3vw]
                        lg:h-[3vw]
                        xl:h-[3vw]
                        2xl:h-[3.5vw]'
                    >
                        <IoLanguage size={'2.5vw'}/>
                    </div>

                    <div className='bg-shopBlue px-2 hidden absolute peer-hover:flex hover:flex flex-col
                        sm:right-[2.7vw]
                        md:right-[4.9vw]
                        lg:right-[6.1vw]
                        xl:right-[7.2vw]
                        2xl:right-[9.2vw]'
                    >
                        <a onClick={() => handleLanguage('ar')} href='#'>
                            <img src={alg} className='
                                sm:h-[3vw]
                                2xl:h-[3.5vw]'
                            />
                        </a>
                        <a onClick={() => handleLanguage('en')} href='#' className='peer'>
                            <img src={en} className='
                                sm:w-[3vw]
                                md:h-[3vw]
                                lg:h-[3vw]
                                xl:h-[3vw]
                                2xl:h-[3.5vw]'
                            />
                        </a>
                        <a onClick={() => handleLanguage('fr')} href='#'>
                            <img src={fr} className='
                                sm:h-[3.2vw]
                                md:h-[3.2vw]
                                lg:h-[3.2vw]
                                xl:h-[3.2vw]
                                2xl:h-[3.5vw]'
                            />
                        </a>
                    </div>
                </div>
                {/** End Languages */}
            </div>

            {/* Start Nav for mobile */}
            <div className='sm:hidden relative right-[4%] text-white font-uniSansRegular'>
                <div className='bg-shopOrange peer p-2 text-[3vw] rounded-sm mb-2'>
                    <HiMenu/>
                </div>

                <div className='
                    hidden py-2 px-4 absolute peer-hover:flex hover:flex flex-col bg-shopBlue
                    right-[-5vw]'
                >
                    <div className='flex flex-col'>
                        <a onClick={() => handleScroll('Home')} className='py-2'>
                            Home
                        </a>
                        <a onClick={() => handleScroll('About')} className='py-2'>
                            About
                        </a>
                        <a onClick={() => handleScroll('Manual')} className='py-2'>
                            Manual
                        </a>
                        <a onClick={() => handleScroll('Contact')} className='py-2'>
                            Contact
                        </a>
                    </div>

                    <div className='text-[2vw]'>
                        <a onClick={() => handleLanguage('ar')} href='#'
                           className='flex justify-between items-center py-1'>
                            <img src={alg} className='h-[4vw] mr-2'
                            />Ar</a>
                        <a onClick={() => handleLanguage('en')} href='#'
                           className='flex justify-between items-center py-1'>
                            <img src={en} className='h-[4vw] mr-2'
                            />En</a>
                        <a onClick={() => handleLanguage('fr')} href='#'
                           className='flex justify-between items-center py-1'>
                            <img src={fr} className='h-[4vw] mr-2'
                            />Fr</a>
                    </div>
                </div>
            </div>
            {/* End Nav for mobile */}
            <DotMenu/>
        </header>
    );
};

function handleScroll(id) {
    document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
    });
}

export default NavBar;
