import React, {useContext, useEffect} from 'react';
import {motion} from 'framer-motion';

import AOS from 'aos';
import 'aos/dist/aos.css';

import NavBar from '../components/home/NavBar'

import phone from '../assets/images/phone-illustration.png';
import appStoreEn from '../assets/images/apple-store-badge.svg';
import appStoreFr from '../assets/images/apple-store-badge-french.svg';
import appStoreAr from '../assets/images/apple-store-badge-arabic.svg';
import googlePlayEn from '../assets/images/google-play-badge.png';
import googlePlayFr from '../assets/images/google-play-badge-french.png';
import googlePlayAr from '../assets/images/google-play-badge-arabic.png';

import {LangContext} from '../Context';

const Home = () => {
    const {language, cookieVal} = useContext(LangContext);
    const {googlePlay, appStore} = handleBadges(cookieVal);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className='
            px-[4vw]
            sm:px-[4vw]
            md:px-[6vw]
            lg:px-[7vw]
            xl:px-[8vw]
            2xl:px-[10vw]'
        >
            <NavBar/>
            <div className='
                mt-[140vw]
                sm:ml-[2vw] sm:mt-[8vw] sm:flex
                md:ml-[2vw] md:mt-[8vw]
                lg:ml-[2vw] lg:mt-[7vw]
                xl:ml-[2vw] xl:mt-[7vw]
                2xl:ml-[2vw] 2xl:mt-[8vw]'
            >
                <div className='
                    flex-col text-center
                    sm:w-3/4 sm:flex-initial sm:text-left
                    md:w-5/6'
                >
                    <div data-aos="fade-right" data-aos-duration="600" data-aos-easing='ease-in-out' className='
                        sm:w-[92%] sm:mb-[0vw] sm:mt-[8vw]
                        md:w-[90%] md:mb-[0vw] md:mt-[8vw]
                        lg:w-[88%] lg:mb-[0vw] lg:mt-[8vw]
                        xl:w-[88%] xl:mb-[0vw] xl:mt-[8vw]
                        2xl:w-[88%] 2xl:mb-[0vw] 2xl:mt-[8vw]'
                    >
                        <div>
                            <h1 className='text-shopBlue font-uniSansSemiBold
                                text-[10vw] mb-[4.5vw]
                                sm:text-[4.5vw] sm:mb-[2.5vw] sm:mt-[5vw]
                                md:text-[4.3vw] md:mb-[2.5vw] md:mt-[5vw]
                                lg:text-[4.2vw] lg:mb-[2.5vw] lg:mt-[5vw]
                                xl:text-[4vw] xl:mb-[2.5vw] xl:mt-[5vw]
                                2xl:text-[3.7vw] 2xl:mb-[2.5vw] 2xl:mt-[5vw]'
                            >{language.homeHeader}</h1>
                        </div>
                        <div>
                            <p className='
                                text-[5vw] font-uniSansRegular
                                sm:text-[2vw] sm:ml-[2vw]
                                md:text-[1.8vw] md:ml-[2vw]
                                lg:text-[1.7vw] lg:ml-[2vw]
                                xl:text-[1.7vw] xl:ml-[2vw]
                                2xl:text-[1.6vw] 2xl:ml-[2vw]'
                            >
                                <span className='text-shopBlue'>SHOP</span>
                                <span className='text-shopOrange'>PEY</span> {language.homeDescription}
                            </p>
                        </div>
                    </div>

                    <div data-aos="fade-up" data-aos-duration="600" data-aos-easing='ease-in-out' data-aos-delay='250'
                         className='
                        sm:gap-x-4 sm:left-[30%] sm:flex sm:items-center sm:relative
                        md:gap-x-4 md:left-[30%]
                        lg:gap-x-4 lg:left-[22%]
                        xl:gap-x-4 xl:left-[22%]
                        2xl:gap-x-4 2xl:left-[21%]'
                    >
                        <a href="https://play.google.com/store/apps/details?id=com.shoppey" className='animate-[pulse_5s_ease_infinite]'>
                            <img src={googlePlay} className='
                                w-[70vw] mx-auto
                                sm:w-[19vw]
                                md:w-[19vw]
                                lg:w-[19vw]
                                xl:w-[18vw]
                                2xl:w-[17vw]'
                            />
                        </a>
                        <a href="#">
                            <img src={appStore} className='opacity-50
                                w-[61vw] mx-auto
                                sm:w-[15vw]
                                md:w-[15vw]
                                lg:w-[15vw]
                                xl:w-[14vw]
                                2xl:w-[14vw]'
                            />
                        </a>
                    </div>
                </div>

                <div data-aos="fade-left" data-aos-duration="600" data-aos-easing='ease-in-out' data-aos-delay='250'
                     className='
                    absolute top-[20vw] left-[20vw]
                    sm:w-1/3 sm:static'
                >
                    <motion.img src={phone} className='
                        w-[60vw]
                        sm:w-[22vw] sm:ml-[4vw]
                        md:w-[21vw] md:ml-[2.2vw]
                        lg:w-[22vw] lg:ml-[0.6vw]
                        xl:w-[30vw] xl:ml-[-2vw]
                        2xl:w-[21vw] 2xl:ml-[0vw]'
                                animate={{
                                    rotate: [0, 2, -2, 2, -2, 2, -2, 0]
                                }}
                                transition={{
                                    delay: 3,
                                    duration: 0.2,
                                    ease: 'easeInOut',
                                    repeat: Infinity,
                                    repeatDelay: 5
                                }}
                    />
                </div>
            </div>
        </div>
    );
};

function handleBadges(val) {
    let googlePlay, appStore = null;

    if (!val || val === 'en') {
        googlePlay = googlePlayEn;
        appStore = appStoreEn;
    } else if (val === 'fr') {
        googlePlay = googlePlayFr;
        appStore = appStoreFr;
    } else {
        googlePlay = googlePlayAr;
        appStore = appStoreAr;
    }

    return {googlePlay, appStore};
}

export default Home;
