import React, {useContext, useEffect} from 'react';
import AboutCarousel from '../components/about/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Partners from '../components/about/Partners';

import {LangContext} from '../Context';

const About = () => {
    const {language} = useContext(LangContext);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div id='About' className='
            mt-[20vw]
            sm:mt-[7vw]
            md:mt-[7vw]
            lg:mt-[7vw]
            xl:mt-[8vw]
            2xl:mt-[10vw]
            mb-[20vw]
            sm:mb-[7vw]
            md:mb-[7vw]
            lg:mb-[7vw]
            xl:mb-[8vw]
            2xl:mb-[10vw]'
        >
            <div className='text-center
                px-[4vw]
                sm:px-[4vw]
                md:px-[6vw]
                lg:px-[7vw]
                xl:px-[8vw]
                2xl:px-[10vw]'
            >
                <div data-aos='fade-right' data-aos-duration='600' data-aos-easing='ease-in-out'>
                    <h2 className='
                        text-shopBlue text-[7vw] font-uniSansSemiBold
                        sm:text-[2.8vw] sm:mb-[0.5vw]
                        md:text-[2.5vw] md:mb-[0.5vw]
                        lg:text-[2.5vw] lg:mb-[0.5vw]
                        xl:text-[2.5vw] xl:mb-[0.5vw]
                        2xl:text-[2.5vw] 2xl:mb-[0.5vw]'
                    >{language.aboutHeader}</h2>
                    <hr className='h-1 bg-shopOrange mx-auto
                        w-[12vw] mb-[10vw]
                        sm:w-[5vw] sm:mb-[3vw]
                        md:w-[5vw] md:mb-[3vw]
                        lg:w-[5vw] lg:mb-[3vw]
                        xl:w-[5vw] xl:mb-[3vw]
                        2xl:w-[5vw] 2xl:mb-[3vw]'
                    />
                </div>

                <h3 className='
                    text-[5vw] mb-[4vw] font-uniSansSemiBold
                    sm:text-[2.5vw] sm:mb-[1.5vw]
                    md:text-[2.3vw] md:mb-[1.5vw]
                    lg:text-[2.3vw] lg:mb-[1.5vw]
                    xl:text-[2.2vw] xl:mb-[1.5vw]
                    2xl:text-[2.1vw] 2xl:mb-[1.5vw]'
                    data-aos='fade-right' data-aos-duration='600' data-aos-easing='ease-in-out'
                >
                    <span className='text-shopBlue'>SHOP</span>
                    <span className='text-shopOrange'>PEY</span> {language.aboutSubHeader}</h3>

                <p className='
                    text-[3.5vw] px-4 text-center font-uniSansRegular
                    sm:text-[1.63vw] sm:mx-[2vw] sm:px-0 sm:text-left
                    md:text-[1.5vw] md:mx-[2vw]
                    lg:text-[1.5vw] lg:mx-[2vw]
                    xl:text-[1.45vw] xl:mx-[2vw]
                    2xl:text-[1.4vw] 2xl:mx-[2vw]'
                   data-aos='zoom-in' data-aos-duration='600' data-aos-easing='ease-in-out'
                >
                    <span className='text-shopBlue'>SHOP</span>
                    <span className='text-shopOrange'>PEY</span> {language.aboutDescription}
                </p>
            </div>
            <AboutCarousel/>
            {/* <Partners/> */}
        </div>
    );
};

export default About;
