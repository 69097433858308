import React from 'react';
import {BsArrowUpCircleFill} from 'react-icons/bs';
import {motion} from 'framer-motion';

const DotMenu = () => {
    return (
        // <div className='flex flex-col py-2 px-1 bg-shopOrange fixed text-center justify-center rounded-full right-2 top-[50%] z-50 w-8 bg-opacity-50'>
        <motion.a
            className='
            right-[1vw] fixed bottom-12 z-50
            sm:right-[1.5vw]'
            onClick={() => document.getElementById('Home').scrollIntoView({behavior: 'smooth'})}
            animate={{
                scale: [1, 1.5, 1],
                rotate: [0, 360, 360, 360, 360, 0],
            }}
            transition={{
                ease: 'easeInOut',
                duration: 6,
                delay: 5,
                repeat: Infinity,
                repeatDelay: 3,
            }}
        >
            <BsArrowUpCircleFill size={'5vw'} className='cursor-pointer opacity-75 text-shopOrange hover:opacity-100'/>
        </motion.a>
        // </div>
    );
};

export default DotMenu;
