




const firebaseConfig = {
    apiKey: "AIzaSyCQ0SNNvYERVJp0EiiyLmkjCv8-2scf2yc",
    authDomain: "shoppey.firebaseapp.com",
    projectId: "shoppey",
    storageBucket: "shoppey.appspot.com",
    messagingSenderId: "589525245720",
    appId: "1:589525245720:web:737a800d24eadfce41086d",
    measurementId: "G-ZLPZXNTFZC"
};


export  default firebaseConfig

