import {useState,useRef,useEffect} from "react";
import axios from "axios"


import {useNavigate} from "react-router-dom";
import {useRecoilState} from "recoil";
import userCredentials from "../atoms/userCredentials";

function Login(){

    const [user,setUser] = useRecoilState(userCredentials);
    const phone_input = useRef();
    const password_input = useRef();
    const navigate = useNavigate()

    useEffect(()=>{

        if(user.length !== 0){

            navigate("/user/deletion")
        }
    },[user])

    return(

        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <div className=" flex flex-row w-full justify-center items-center">

                <img className=" w-[25vh] h-[25vh] " src={require("../assets/icons/logo.png")}/>
                </div>
                <h1 className="text-2xl font-uniSansSemiBold text-center text-shopBlue underline">
                    Sign in to shoppey
                </h1>

                    <div className="mb-2">
                        <label
                            htmlFor="phone"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Phone number
                        </label>
                        <input
                            type="tel"
                            name="phone"
                            ref={phone_input}
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"

                            required={true}
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            htmlFor="password"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            ref={password_input}
                            className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                            required={true}
                        />
                    </div>

                    <div className="mt-6">
                        <button onClick={()=>{

                            if(phone_input.current.value !== null && password_input.current.value !== null) {
axios.post("https://api1.shoppey.net/api/login",{

    phoneNumber:phone_input.current.value,
    password: password_input.current.value
}).then((res)=>{
if(res.status === 200){
    setTimeout(()=>{
        setUser(res.data);
        navigate("/user/deletion")
    },500)


}

}).catch((err)=>{
    console.error(err)
})
                            }
                        }} className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-shopOrange rounded-md hover:bg-shopBlue focus:outline-none focus:bg-purple-600">
                            Login
                        </button>
                    </div>



            </div>
        </div>
    )

}

export default Login;
