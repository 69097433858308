import React, {useEffect} from 'react';
import {Carousel} from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import carousel1 from '../../assets/carousel/carousel-1.png';
import carousel2 from '../../assets/carousel/carousel-2.jpg';
import carousel3 from '../../assets/carousel/carousel-3.jpg';

const AboutCarousel = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div data-aos='fade-right' data-aos-duration='600' data-aos-easing='ease-in-out' className='
            flex justify-center bg-shopBlue p-2 mt-[10vw]
            sm:mt-[2vw]
            md:mt-[2vw]
            lg:mt-[2vw]
            xl:mt-[2vw]
            2xl:mt-[2vw]'
        >
            <div className='
                w-[100%]
                sm:w-[80%]
                md:w-[70%]
                lg:w-[55%]
                xl:w-[52%]
                2xl:w-[50%]'
            >
                <Carousel autoPlay={true} infiniteLoop={true} interval={5000}>
                    {/* <div>
                        <img src={carousel1}/>
                        <p className='legend'>SHOPPEY</p>
                    </div> */}
                    <div>
                        <img src={carousel2}/>
                        <p className='legend'>SHOPPEY</p>
                    </div>
                    <div>
                        <img src={carousel3}/>
                        <p className='legend'>SHOPPEY</p>
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default AboutCarousel;
