export default {
    home: 'الرئيسية',
    about: 'حول',
    manual: 'الدليل',
    contact: 'تواصل معنا',

    shoppeyBlue: 'SHOP',
    shoppeyOrange: 'PEY',

    homeHeader: 'قم بتنزيل تطبيق الجوال',
    homeDescription: 'هو تطبيق جوال للتجارة الإلكترونية يسمح لشركات البيع بالتجزئة بملء مخزونهم بمجموعة متنوعة من المنتجات بأفضل أسعار السوق',

    aboutHeader: 'حول',
    aboutSubHeader: 'يضع القوة الشرائية في أيدي تجار التجزئة',
    aboutDescription: 'هي شركة حديثة تقوم بتسليم منتجاتك إلى متجرك، مما يسمح لك بملء مخزونك بضغطة زر، نحن الشركة الوحيدة التي تقدم هذه الخدمة، حاليًا نحن نعمل فقط في سطيف ولكننا سنتوسع إلى السوق الجزائري بأكمله.',

    manualHeader: 'استخدم تطبيق بسهولة SHOPPEY',
    stepOneTitle: 'مكان واحد لجميع الاحتياجات',
    stepOneDescription: 'يمكنك الوصول إلى العديد من المنتجات من أفضل العلامات التجارية وتجار الجملة بأفضل الأسعار والعروض الترويجية في الوقت الفعلي عبر هاتفك المحمول',
    stepTwoTitle: 'اختر المنتجات التي تريدها',
    stepTwoDescription: 'حدد المنتج الذي تريده بكمية مرنة وقم بتجديد مخزونك في أقل من 24 ساعة بنقرة بسيطة',
    stepThreeTitle: 'تتبع طلبك واستلمه',
    stepThreeDescription: 'تتبع طلبك من خلال تحديث الحالة في الوقت الفعلي واستلم منتجاتك في المكان الذي تختاره بالطريقة الأكثر ملاءمة',

    contactHeader: 'تواصل معنا',
    formSubject: 'الموضوع',
    formMessage: 'الرسالة',
    formSend: 'إرسال',
    contactUs: 'تواصل معنا',
    callOrText: 'اتصال أو إرسال رسالة نصية',
    website: 'الموقع إلكتروني',
    address: 'العنوان',
    email: 'البريد الإلكتروني',
};