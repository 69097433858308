import logo1 from "../assets/images/logo1.png"

import {useContext, useEffect, useRef, useState} from "react"
import {MapContainer, Marker, TileLayer} from "react-leaflet"
import "leaflet/dist/leaflet.css"
import L from "leaflet"

import {LangContext} from "../Context";


import AOS from 'aos';
import 'aos/dist/aos.css';


function Contact() {
    const {language} = useContext(LangContext);

    const [center, setCenter] = useState({lat: 36.17444, lng: 5.41522});
    const ZOOM_LEVEL = 9;
    const mapRef = useRef();

    const markerIcon = new L.Icon({
        iconUrl: require("../assets/icons/logo.png"),
        iconSize: [55, 55]
    })

    useEffect(() => {
        AOS.init();
    }, []);

    const [formData, setFormData] = useState({
        subject: 'inquiry',
        message: '',
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here, you can perform any action with the form data, like sending it to a server.
        // For this example, we'll just log the data to the console.
        console.log(formData);
    };

    return (
        <>
            <div id="Contact" className="bg-shopBlue  mx-auto px-7 p-2 mt-[10vw]
            sm:mt-[2vw]
            md:mt-[2vw]
            lg:mt-[2vw]
            xl:mt-[2vw]
            2xl:mt-[2vw]">
                <h1 data-aos="fade-up" data-aos-duration="1200"
                    className="text-3xl text-center pb-20 text-white  pt-5 font-uniSansSemiBold underline decoration-shopOrange underline-offset-8 decoration-1">{language.contactHeader}</h1>


                <div className="md:flex md:lex-row pt-5 ">

                    <div className="basis-1/2 pb-5" data-aos="fade-right" data-aos-duration="1200">
                        <div id="map" className="  bg-white" style={{width: '100%', height: '400px'}}>

                            <MapContainer
                                center={center}
                                zoom={ZOOM_LEVEL}
                                ref={mapRef}
                                style={{width: '100%', height: '400px'}}
                                attributionControl={false}
                            >
                                <TileLayer
                                    url={"https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=NLALtYfKMjndQJaeLrE2"}
                                    attribution={''}>
                                </TileLayer>
                                <Marker position={[36.17444, 5.41522]} icon={markerIcon}/>


                            </MapContainer>

                        </div>

                    </div>

                    <div className="basis-1/2 md:pl-10" data-aos="fade-right" data-aos-duration="1200">


                        <form onSubmit={handleSubmit} className="p-4 bg-white shadow-md rounded-lg">
                            <div className="mb-4">
                                <label htmlFor="subject" className="block text-black font-uniSansSemiBold mb-2">
                                    {language.formSubject}:
                                </label>
                                <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    value={formData.subject}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md outline-none focus:border-shopBlue"
                                />
                            </div>


                            <div className="mb-4">
                                <label htmlFor="message" className="block text-black font-uniSansSemiBold mb-2">
                                    {language.formMessage}:
                                </label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="w-full px-3 py-2 border rounded-md resize-none h-40 outline-none focus:border-shopBlue"
                                />
                            </div>
                            <button

                                className="bg-shopOrange hover:bg-shopBlue text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            >
                                <a className="text-white text-[13px] font-uniSansBook"
                                   href={"mailto:info@shoppey.cloud?subject=" + formData.subject + "&body=" + formData.message}>{language.formSend}</a>
                            </button>
                        </form>


                    </div>

                </div>


                <div className="flex lex-row justify-center pb-5">


                </div>
                <hr></hr>
                <div className="flex lex-row pt-5">
                    <div className="basis-1/2 pb-10">
                        <img className="pb-5" src={logo1}></img>
                        <p className="text-[13px] text-white font-uniSansSemiBold"><b>SARL SHOPPEY PLUS</b></p>
                        <p className="text-[13px] text-white font-uniSansRegular">{language.address} : Zone d'activté section 324,ilo 06,Setif</p>
                    </div>

                    <div className="basis-1/2 pl-10">

                        <p className="text-[15px] text-white font-uniSansSemiBold"><b>{language.contactUs}</b></p>
                        <p className="text-[13px] text-white font-uniSansRegular">Email : info@shoppey.cloud</p>
                        <p className="text-[13px] text-white font-uniSansRegular">{language.callOrText} : +213 (0) 561
                            68 29 59</p>
                        <p className="text-[13px] text-white font-uniSansRegular">{language.website} :
                            www.shoppey.net</p>

                    </div>

                </div>

            </div>
        </>
    );
}

export default Contact;
