import phone1 from "../assets/images/phone1.png"

import {useContext, useEffect} from "react";

import {LangContext} from "../Context";


import AOS from 'aos';
import 'aos/dist/aos.css';


function Manual() {
    const {language} = useContext(LangContext);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <div id="Manual" className="container mx-auto
            px-[4vw]
            sm:px-[4vw]
            md:px-[6vw]
            lg:px-[7vw]
            xl:px-[8vw]
            2xl:px-[10vw]
            mt-[20vw]
            sm:mt-[7vw]
            md:mt-[7vw]
            lg:mt-[7vw]
            xl:mt-[8vw]
            2xl:mt-[10vw]
            mb-[20vw]
            sm:mb-[7vw]
            md:mb-[7vw]
            lg:mb-[7vw]
            xl:mb-[8vw]
            2xl:mb-[10vw]"
            >
                <div>
                    <h1 className=" text-center
                        text-shopBlue text-[7vw] font-uniSansSemiBold
                        sm:text-[2.8vw] sm:mb-[0.5vw]
                        md:text-[2.5vw] md:mb-[0.5vw]
                        lg:text-[2.5vw] lg:mb-[0.5vw]
                        xl:text-[2.5vw] xl:mb-[0.5vw]
                        2xl:text-[2.5vw] 2xl:mb-[0.5vw]">
                        {language.manualHeader}
                    </h1>
                    {/* <hr className="mx-auto w-10 text-shopOrange "></hr> */}
                    <hr className='h-1 bg-shopOrange mx-auto
                        w-[70vw] mb-[10vw]
                        sm:w-[25vw] sm:mb-[3vw]
                        md:w-[20vw] md:mb-[3vw]
                        lg:w-[20vw] lg:mb-[3vw]
                        xl:w-[20vw] xl:mb-[3vw]
                        2xl:w-[20vw] 2xl:mb-[3vw]'
                    />
                </div>


                <div className="md:flex md:flex-row">

                    <div className="basis-1/2 justify-center" data-aos="fade-right" data-aos-duration="3000">
                        <img className=" hover:rotate-6 bounce-short" src={phone1}></img>
                    </div>


                    <div className="basis-1/2 pt-20" data-aos="fade-right" data-aos-duration="3000">

                        <div className="bg-shopOrange pl-1 drop-shadow-xl hover:skew-x-6">
                            <div className="bg-orange-100 p-5">
                                <h3 className="text-2xl text-shopOrange pb-3 font-uniSansRegular">{language.stepOneTitle}</h3>
                                <p className="text-[2vh] font-uniSansBook">{language.stepOneDescription}</p>
                            </div>
                        </div>

                        <br></br>

                        <div className="bg-shopBlue pl-1 drop-shadow-xl hover:skew-x-6">
                            <div className="bg-blue-100 p-5">
                                <h3 className="text-2xl text-shopBlue pb-3 font-uniSansRegular">{language.stepTwoTitle}</h3>

                                <p className="text-[2vh] font-uniSansBook">{language.stepTwoDescription}</p>
                            </div>

                        </div>

                        <br></br>
                        <div className="bg-shopOrange pl-1 mb-5 drop-shadow-xl hover:skew-x-6">
                            <div className="bg-orange-100 p-5">
                                <h3 className="text-2xl text-shopOrange pb-3 font-uniSansRegular">{language.stepThreeTitle}</h3>
                                <p className="text-[2vh] font-uniSansBook">{language.stepThreeDescription}</p>
                            </div>
                        </div>


                    </div>


                </div>


            </div>


        </>
    );
}

export default Manual;
