import React, {useState} from 'react';
import {Cookies} from 'react-cookie';
import { RecoilRoot } from 'recoil';

import  firebaseConfig from './firebase'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import english from './languages/english';
import french from './languages/french';
import arabic from './languages/arabic';

import background from '../src/assets/images/Background.png';

import Home from './pages/Home';
import About from './pages/About';
import Manual from './pages/Manual'
import Contact from './pages/Contact'

import Delete_user_data from "./pages/deletation";

import {LangContext} from './Context';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./pages/Login";

const cookies = new Cookies();
const AppMain = () => {
    return(
        <div className='scroll-smooth overflow-x-auto'>
            <img src={background} className='absolute top-0 z-[-100] w-full
                        sm:h-[43vw]
                        md:h-[43vw]
                        lg:h-[40vw]
                        xl:h-[40vw]
                        2xl:h-[40vw]'
            />
            <div
            >
                <Home/>
                <About/>
                <Manual/>
                <Contact/>
            </div>
        </div>)
}
function App() {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    const cookieName = 'saved Language';
    let cookieVal = cookies.get(cookieName);
    const choosenLang = cookieVal ? resolveLanguage(cookieVal) : english;

    const [language, setLanguage] = useState(choosenLang);



    function handleLanguage(lang) {
        if (!cookieVal)
            cookies.set(cookieName, lang);

        if (cookieVal !== lang) {
            cookies.remove(cookieVal);
            cookies.set(cookieName, lang);
        }

        window.location.reload(true);
    };

    return (
        <RecoilRoot>
            <LangContext.Provider value={{language, handleLanguage, cookieVal}}>
                <Router>
                    <Routes>
                        <Route path="/" element={<AppMain />} />
                        <Route path="/user/login" element={<Login />} />
                        <Route path="/user/deletion" element={<Delete_user_data />} />
                    </Routes>
                </Router>
            </LangContext.Provider>
        </RecoilRoot>
    );
}

function resolveLanguage(lang) {
    let language;

    switch (lang) {
        case 'en':
            language = english;
            break;
        case 'fr':
            language = french
            break;
        case 'ar':
            language = arabic
            break;
        default:
            language = english;
            break;
    }

    return language;
}

export default App;
