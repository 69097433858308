export default {
    home: 'Accueil',
    about: 'À-propos',
    manual: 'Manuel',
    contact: 'Contact',

    shoppeyBlue: 'SHOP',
    shoppeyOrange: 'PEY',

    homeHeader: 'Téléchargez notre application mobile',
    homeDescription: 'est une application mobile de e-commerce qui permet aux commerces de détail de remplir leur stock avec une variété de produits aux meilleurs prix du marché',

    aboutHeader: 'À-PROPOS',
    aboutSubHeader: 'met le pouvoir d\'achat entre les mains des détaillants',
    aboutDescription: 'est une entreprise moderne qui livre vos produits dans votre magasin, vous permettant de remplir votre inventaire en un clic, nous sommes la seule entreprise qui fournit ce service, actuellement nous n\'agissons qu\'à Sétif mais nous allons nous étendre à tout le marché Algérien.',

    manualHeader: 'Utilisez l\'application SHOPPEY en toute simplicité',
    stepOneTitle: 'Un seul endroit pour tous les besoins',
    stepOneDescription: 'Accédez à plusieurs produits des meilleures marques et grossistes avec les meilleurs prix et des promotions en temps réel via votre mobile',
    stepTwoTitle: 'Choisissez vos produits désirés',
    stepTwoDescription: 'Sélectionnez le produit que vous désirez avec une quantité flexible et réapprovisionnez votre stock en moins de 24h d\'un simple clic',
    stepThreeTitle: 'Suivez et recevez votre commande',
    stepThreeDescription: 'Suivez votre commande avec une mise à jour de statut en temps réel et recevez vos produits à l\'emplacement de votre choix de la manière la plus pratique',

    contactHeader: 'CONTACT',
    formSubject: 'Sujet',
    formMessage: 'Message',
    formSend: 'Envoyer',
    contactUs: 'CONTACTEZ-NOUS',
    callOrText: 'Appel ou SMS',
    website: 'Site-web',
    address: 'Adresse',
};