import {useEffect, useRef} from "react"

import userCredentials from "../atoms/userCredentials";
import {useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import axios from "axios";

function Delete_user_data() {
    const user = useRecoilValue(userCredentials);
    const navigate = useNavigate();
    const email = useRef();
    useEffect(() => {
        if (user?.idUserOdoo === undefined) {
            navigate("/user/login")
        }
        console.log(user)
    }, [])

    return (

        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-md lg:max-w-xl">
                <div className=" flex flex-col w-full justify-left items-center">
                    <h2 className="text-2xl font-uniSansSemiBold">You are about to request deletion of your information
                        from Shoppey +</h2>
                    <ul className="list-disc mt-3">
                        <li> All your personal information will be deleted within 30 days</li>
                        <li className="font-bold"> All your purchase history will be <u>kept in our systems</u></li>

                    </ul>

                </div>
                <h1 className="text-2xl font-uniSansSemiBold text-center text-shopBlue underline">

                </h1>

                <div className="mb-2">
                    <label
                        htmlFor="email"
                        className="block text-sm font-semibold text-gray-800"
                    >
                        Email address
                    </label>
                    <input
                        type="email"
                        name="email"
                        ref={email}
                        className="block w-full px-4 py-2 mt-2 text-purple-700 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"

                        required={true}
                    />
                </div>

                <div className="mt-6">
                    <button onClick={() => {
                        if (email.current.value === user.email) {
                            axios.post("https://api2.shoppey.net/request_deletation",{
                                user
                            }).then((res)=>{
                                if(res.data.result === "success"){
                                    alert("Your request has been successfully sent to Shoppey + ")
                                    navigate("/")
                                }else{
                                    alert("Something went wrong ")
                                }
                            }).catch((err)=>{

                            })
                        } else {
                            alert("Please input the correct email address")
                        }
                    }}
                            className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:bg-red-700">
                        Send request
                    </button>
                </div>


            </div>
        </div>
    )

}

export default Delete_user_data;
