export default {
    home: 'Home',
    about: 'About',
    manual: 'Manual',
    contact: 'Contact',

    shoppeyBlue: 'SHOP',
    shoppeyOrange: 'PEY',

    homeHeader: 'Download our mobile app',
    homeDescription: 'is an e-commerce mobile app that allows retail businesses to fill their stock with a variety of products at the best market prices',

    aboutHeader: 'ABOUT',
    aboutSubHeader: 'puts the purchasing power in the hands of retailers',
    aboutDescription: 'is a modern company that delivers your products to your store, allowing you to fill your inventory with a click of a button, we are the only company that provides this service, currently we are only acting in Setif but we will expand to the whole Algerian market.',

    manualHeader: 'Use SHOPPEY App with Ease',
    stepOneTitle: 'One Place for All Needs',
    stepOneDescription: 'Access multiple products from top brands & wholesalers with best prices and real-time promotions via your mobile',
    stepTwoTitle: 'Choose your Desired Products',
    stepTwoDescription: 'Select the product that you desire with a flexible quantity & replenish your stock in less than 24hrs with just a simple click',
    stepThreeTitle: 'Track & Receive your Order',
    stepThreeDescription: 'Track your order with a real-time status update & receive your products at your chosen location in the most convenient way',

    contactHeader: 'CONTACT',
    formSubject: 'Subject',
    formMessage: 'Message',
    formSend: 'Send',
    contactUs: 'CONTACT US',
    callOrText: 'Call or Text',
    website: 'Website',
    address: 'Address',
};